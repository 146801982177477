<template>
  <div>
    <v-img :src="require(isDark ? '@/assets/futflogga_dark.png' : '@/assets/futflogga.png')"
    style="margin: 2rem"></v-img>

    <v-container>
      <h1>Om FUTF</h1>
    </v-container>

    <paragraph-component>
      <textloader-component textKey="FUTFBrödtext"/>
    </paragraph-component>

    <v-row justify="center" align="center">
      <v-col cols="3">
        <paragraph-component title="Utbildningsutskottet">
          <textloader-component textKey="UtbBrödtext"/>
        </paragraph-component>
      </v-col>
      <v-col cols="3">
        <v-img :src="require('@/assets/Information/Futf/utb-utskottet.jpg')" contain height=20em></v-img>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="3">
        <v-img :src="require('@/assets/Information/Futf/arb-utskott.jpg')" height=20em></v-img>
      </v-col>
      <v-col cols="3">
        <paragraph-component title="Arbetsmarknadsutskottet">
          <textloader-component textKey="ArbBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="3">
        <paragraph-component title="Fredagsfikat">
          <textloader-component textKey="FredagsfikatBrödtext"/>
        </paragraph-component>
      </v-col>
      <v-col cols="3">
        <v-img :src="require('@/assets/Information/Futf/fredagsfikat.png')" height=20em></v-img>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="3">
        <v-img :src="require('@/assets/Information/Futf/klubbverket.jpg')" height=20em></v-img>
      </v-col>
      <v-col cols="3">
        <paragraph-component title="Klubbverket">
          <textloader-component textKey="KVBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="3">
        <paragraph-component title="Träningsverket">
          <textloader-component textKey="TräningsverketBrödtext"/>
        </paragraph-component>
      </v-col>
      <v-col cols="3">
        <v-img :src="require('@/assets/Information/Futf/träningsverketplaceholder.jpg')" height=20em></v-img>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="3">
        <v-img :src="require('@/assets/Information/Futf/gettebra.jpg')" height=20em></v-img>
      </v-col>
      <v-col cols="3">
        <paragraph-component title="IT-gruppen">
          <textloader-component textKey="ITgruppenBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>
    <v-img :src="
          require(isDark
            ? '@/assets/utn_vit.png'
            : '@/assets/utn_svart.png')
        " max-width="30%" class="center"></v-img>

    <paragraph-component title="UTN">
      <textloader-component textKey="FUTFBrödtextUTN"/>
    </paragraph-component>

    <v-img :src="require(isDark ? '@/assets/Information/Futf/utn_flagga.jpg' : '@/assets/Information/Futf/utn_flagga.jpg')"
    max-width="40%" class="center"
    ></v-img>
    <br>

  </div>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'

export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  computed: {
    isDark: function () { return this.$vuetify.theme.dark }
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
